import { useEffect, useState } from 'react';
import { auth } from '../config/firebaseConfig';

export const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      console.log('Auth State Changed: ', user);
    });

    return () => unsubscribe(); 
  }, []);

  return { currentUser };
};
