import PropTypes from 'prop-types';
import { useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// project import
import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';
import { drawerWidth } from 'config';

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const MainDrawer = ({ open, handleDrawerToggle, window }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  // responsive drawer container
  const container = window !== undefined ? () => window().document.body : undefined;

  // header content
  const drawerContent = useMemo(() => <DrawerContent />, []);
  const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1300 }} aria-label="mailbox folders">
      {!matchDownMD ? (
        <MiniDrawerStyled variant="permanent" open={open} sx={{ 
          '& .MuiDrawer-paper': { 
            bgcolor: '#ffffff', 
            color: '#000000',
            '& .MuiListItemButton-root': {
              '&:hover': {
                bgcolor: 'rgba(255, 165, 0, 0.1)', // 연한 주황색
                '& .MuiListItemIcon-root': {
                  color: '#000000',
                },
                '& .MuiTypography-root': {
                  color: '#000000',
                }
              },
              '&.Mui-selected': {
                bgcolor: 'rgba(255, 165, 0, 0.2)', // 어두운 주황색
                '& .MuiListItemIcon-root': {
                  color: '#ffffff',
                },
                '& .MuiTypography-root': {
                  color: '#ffffff',
                },
                '&:hover': {
                  bgcolor: 'rgba(255, 165, 0, 0.2)', // 어두운 주황색
                }
              }
            },
            '& .MuiListItemIcon-root': {
              color: 'rgba(0, 0, 0, 0.7)',
            },
            '& .MuiTypography-root': {
              color: 'rgba(0, 0, 0, 0.7)',
              fontSize: '1.1rem', // 글자 크기 조정
              fontWeight: 'bold' // 글자 굵기 조정
            }
          } 
        }}>
          {drawerHeader}
          {drawerContent}
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              borderRight: `1px solid ${theme.palette.divider}`,
              backgroundImage: 'none',
              boxShadow: 'inherit',
              bgcolor: '#ffffff',
              color: '#000000',
              '& .MuiListItemButton-root': {
                '&:hover': {
                  bgcolor: 'rgba(255, 165, 0, 0.1)', // 연한 주황색
                  '& .MuiListItemIcon-root': {
                    color: '#000000',
                  },
                  '& .MuiTypography-root': {
                    color: '#000000',
                  }
                },
                '&.Mui-selected': {
                  bgcolor: 'rgba(255, 165, 0, 0.2)', // 어두운 주황색
                  '& .MuiListItemIcon-root': {
                    color: '#ffffff',
                  },
                  '& .MuiTypography-root': {
                    color: '#ffffff',
                  },
                  '&:hover': {
                    bgcolor: 'rgba(255, 165, 0, 0.2)', // 어두운 주황색
                  }
                }
              },
              '& .MuiListItemIcon-root': {
                color: 'rgba(0, 0, 0, 0.7)',
              },
              '& .MuiTypography-root': {
                color: 'rgba(0, 0, 0, 0.7)',
                fontSize: '1.1rem', // 글자 크기 조정
                fontWeight: 'bold' // 글자 굵기 조정
              }
            }
          }}
        >
          {open && drawerHeader}
          {open && drawerContent}
        </Drawer>
      )}
    </Box>
  );
};

MainDrawer.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default MainDrawer;
