import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import { store } from 'store';
import reportWebVitals from './reportWebVitals';

import { AuthProvider } from './AuthContext';

import 'simplebar/src/simplebar.css'; // scroll bar styles
import 'assets/third-party/apex-chart.css'; // apex-chart styles

// 메인 컨테이너를 선택합니다.
const container = document.getElementById('root');
const root = createRoot(container);

// 렌더링 부분입니다.
root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter basename="/">
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </ReduxProvider>
  </StrictMode>
);

// 웹 사이트의 성능을 측정할 경우 사용합니다. 필요 없다면 제거할 수 있습니다.
reportWebVitals();
