import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import Dashboard from './DashboardRoutes';
import Product from './ProductRoutes';
import Admin from './AdminRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([Dashboard, Product, LoginRoutes, Admin]);
}
