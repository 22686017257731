import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
//import MainLayout from 'layout/MainLayout';

// render - dashboard
const ProductDefault = Loadable(lazy(() => import('pages/product')));
const Detail_1 = Loadable(lazy(() => import('pages/product/detail_1')));
const Detail_2 = Loadable(lazy(() => import('pages/product/detail_2')));
const Detail_3 = Loadable(lazy(() => import('pages/product/detail_3')));

// ==============================|| MAIN ROUTING ||============================== //

const ProductRoutes = {
  path: '/product',
  //element: <MainLayout />,
  children: [
    {
      path: '',
      element: <ProductDefault />
    },
    {
      path: 'default',
      element: <ProductDefault />
    },
    {
      path: 'detail_1',
      element: <Detail_1 />
    },
    {
      path: 'detail_2',
      element: <Detail_2 />
    },
    {
      path: 'detail_3',
      element: <Detail_3 />
    }
  ]
};

export default ProductRoutes;
