import { lazy } from 'react';
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

const AdminDefault = Loadable(lazy(() => import('pages/admin/Admin')));
const UserManagement = Loadable(lazy(() => import('pages/admin/UserManagement')));
const BlockUnblock = Loadable(lazy(() => import('pages/admin/BlockUnblock')));
const AccountManagement = Loadable(lazy(() => import('pages/admin/AccountManagement')));
const MarketingManagement = Loadable(lazy(() => import('pages/admin/MarketingManagement')));
const AdminAccountManagement = Loadable(lazy(() => import('pages/admin/AdminAccountManagement'))); // New Import

const AdminRoutes = {
  path: '/admin',
  element: <MainLayout />,
  children: [
    {
      path: '',
      element: <AdminDefault />
    },
    {
      path: 'user-management',
      element: <UserManagement />
    },
    {
      path: 'block-unblock',
      element: <BlockUnblock />
    },
    {
      path: 'account-management',
      element: <AccountManagement />
    },
    {
      path: 'marketing-management',
      element: <MarketingManagement />
    },
    {
      path: 'admin-account-management',
      element: <AdminAccountManagement /> // New Route
    }
  ]
};

export default AdminRoutes;
