// 로고 이미지 파일 불러오기
import logo from '../../assets/images/logo/logo3.png'; // 적절한 파일 경로를 확인하세요.

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  return (
    // 이미지 태그로 로고 표시
    <img src={logo} alt="Logo" width="200" />
  );
};

export default Logo;
