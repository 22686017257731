// material-ui
import { Box, useMediaQuery, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../AuthContext'; // 경로 수정

// project import
import Search from './Search';
import Profile from './Profile';
// import Notification from './Notification';
import MobileSection from './MobileSection';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuthContext(); // Get authentication state and logout function

  const handleLogin = () => {
    navigate('/login');
  };

  const handleLogout = async () => {
    await logout();
  };

  const pastelOrange = {
    backgroundColor: '#FFB347',
    '&:hover': {
      backgroundColor: '#FFA07A'
    }
  };

  return (
    <>
      {!matchesXs && <Search />}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      {/* <Notification /> */}
      {!isAuthenticated ? (
        <Button variant="contained" onClick={handleLogin} sx={{ ml: 1, ...pastelOrange }}>
          Login
        </Button>
      ) : (
        <Button variant="contained" onClick={handleLogout} sx={{ ml: 1, ...pastelOrange }}>
          Logout
        </Button>
      )}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
