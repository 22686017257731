// assets
import { ChromeOutlined, QuestionOutlined } from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support = {
  id: 'support',
  title: '',
  type: 'group',
  children: [
    {
      id: 'report-center',
      title: 'Report Center',
      type: 'item',
      url: '/report-center',
      icon: icons.ChromeOutlined
    },
    {
      id: 'user-profile',
      title: 'User Profile',
      type: 'item',
      url: '/user-profile',
      icon: icons.ChromeOutlined
    },
    {
      id: 'broker',
      title: 'Broker',
      type: 'item',
      url: '/broker',
      icon: icons.ChromeOutlined
    },
    {
      id: 'news',
      title: 'News',
      type: 'item',
      url: '/news',
      icon: icons.ChromeOutlined
    }
    /*
    {
      id: 'ea',
      title: 'EA',
      type: 'item',
      url: '/ea',
      icon: icons.ChromeOutlined
    },
    {
      id: 'documentation',
      title: 'Documentation',
      type: 'item',
      url: 'https://codedthemes.gitbook.io/mantis/',
      icon: icons.QuestionOutlined,
      external: true,
      target: true
    }*/
  ]
};

export default support;
