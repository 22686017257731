import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const DashboardWrapper = Loadable(lazy(() => import('pages/dashboard/DashboardWrapper')));

// render - report center page
const ReportCenter = Loadable(lazy(() => import('pages/extra-pages/reportcenter')));
const UserProfile = Loadable(lazy(() => import('pages/extra-pages/userProfile')));
const Broker = Loadable(lazy(() => import('pages/extra-pages/Broker')));
const News = Loadable(lazy(() => import('pages/extra-pages/News')));
const EA = Loadable(lazy(() => import('pages/extra-pages/EA')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const DashboardRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardWrapper />
    },
    {
      path: 'dashboard',
      element: <DashboardWrapper />
    },
    {
      path: 'dashboard/:nodeId',
      element: <DashboardDefault />
    },
    {
      path: 'color',
      element: <Color />
    },
    {
      path: 'report-center',
      element: <ReportCenter />
    },
    {
      path: 'user-profile',
      element: <UserProfile />
    },
    {
      path: 'broker',
      element: <Broker />
    },
    {
      path: 'news',
      element: <News />
    },
    {
      path: 'ea',
      element: <EA />
    },
    {
      path: 'shadow',
      element: <Shadow />
    },
    {
      path: 'typography',
      element: <Typography />
    },
    {
      path: 'icons/ant',
      element: <AntIcons />
    }
  ]
};

export default DashboardRoutes;
