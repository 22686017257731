import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useAuth } from './hooks/useAuth';
import { auth } from './config/firebaseConfig';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [uid, setUid] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      setUid(currentUser.uid);
      setIsAuthenticated(true);
      console.log('User is logged in:', currentUser.uid);
    } else {
      setUid(null);
      setIsAuthenticated(false);
      console.log('User is logged out');
    }
  }, [currentUser]);

  const login = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('Login successful');
      navigate('/');
    } catch (error) {
      console.error('Failed to log in', error);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setIsAuthenticated(false);
      setUid(null);
      console.log('Logout successful');
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  return <AuthContext.Provider value={{ isAuthenticated, uid, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
